@charset "UTF-8";

// ==================================================
//  @use
//  mixin: @include index.sp {~}
//  color: color.$write-main
// ==================================================
@use "mixin" as mixin;
@use "color" as color;

// ==================================================
//  Page Top
// ==================================================
.page-top {
  & + .footer {
    margin-top: 0 !important;
  }

  // Mainvisual
  .mainvisual {
    display: block;
    width: 100%;
    height: auto;
    margin-top: -11px;

    @include mixin.pc {
      aspect-ratio: 1/0.563;
    }

    @include mixin.sp {
      height: calc(80vh - 90px);
      margin-top: 0;
    }

    &_inner {
      display: block;
      width: 100%;
      max-width: calc(100% - 320px);
      height: auto;
      background-color: #ccc;
      position: fixed;
      z-index: 10;
      top: 0;
      left: 320px;
      border-radius: 0 0 0 50px;
      overflow: hidden;

      @include mixin.pc {
        aspect-ratio: 1/0.563;
      }

      @include mixin.sp {
        max-width: none;
        height: calc(80vh - 90px);
        border-radius: 0 0 0 30px;
        top: 90px;
        left: 0;
      }

      &::before {
        content: "";
        display: block;
        width: 100%;
        height: 11px;
        background-color: #fbe0dd;
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;

        @include mixin.sp {
          display: none;
        }
      }
    }

    &_txt01 {
      display: block;
      width: 100%;
      max-width: 469px;
      position: absolute;
      z-index: 2;
      top: 135px;
      left: 68px;

      @include mixin.sp {
        max-width: 234px;
        top: auto;
        bottom: 134px;
        left: 15px;
      }
    }

    &_txt02 {
      display: block;
      width: 100%;
      max-width: 453px;
      position: absolute;
      z-index: 2;
      top: 236px;
      left: 68px;

      @include mixin.sp {
        max-width: 320px;
        top: auto;
        bottom: 40px;
        left: 15px;
      }
    }

    &_youtube {
      display: block;
      position: absolute;
      z-index: 1;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 177.77777778vh; // 16:9 の幅→16 ÷ 9＝ 177.77%
      height: 56.25vw; // 16:9の幅 → 9 ÷ 16 = 56.25%
      min-height: 100%;
      min-width: 100%;
    }

    // Javascript
    .youtube-mask {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      background-color: transparent;
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
    }
  }

  // Mainvisual View
  .mainvisualView {
    display: block;
    width: 100%;
    height: auto;
    aspect-ratio: 1/0.563;
  }

  // Topics
  .topics {
    display: block;
    width: 100%;
    padding: 18px 49px 18px 0;
    background-color: #fff;

    @include mixin.sp {
      padding: 30px 20px;
    }

    &_item {
      &_inner {
        display: flex;
        text-decoration: none;
        color: inherit;
        background-color: #fdefec;
        border-radius: 48px;
        overflow: auto;

        @include mixin.sp {
          flex-direction: column;
          border-radius: 0;
          padding: 15px;
        }
      }

      &_date {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 151px;
        font-size: 1.6rem;
        line-height: calc(24 / 16);
        color: #fff;
        margin: 0;
        background-color: color.$site-main;
        border-radius: 0 48px 48px 0;
        padding: 21px 20px 22px;

        @include mixin.sp {
          width: 110px;
          font-size: 1.3rem;
          border-radius: 30px;
          padding: 3px 10px;
        }
      }

      &_title {
        display: block;
        width: calc(100% - 151px);
        font-size: 1.6rem;
        line-height: calc(24 / 16);
        margin: 0;
        padding: 21px 24px 22px;

        @include mixin.sp {
          width: 100%;
          font-size: 1.4rem;
          padding: 8px 0 0;
        }
      }
    }
  }

  // News
  .news {
    display: block;
    width: 100%;
    background-color: #fff;
    border-radius: 50px 0 0 0;

    @include mixin.sp {
      border-radius: 30px 0 0 0;
    }

    &_inner {
      display: block;
      width: 100%;
      border-radius: 50px 0 0 50px;
      overflow: hidden;
      background-color: #fdefec;
      padding: 19px 0 94px;

      @include mixin.sp {
        border-radius: 30px 0 0 30px;
        padding: 20px 0 40px;
      }
    }

    &_title {
      width: 100%;
      max-width: 944px;
      margin: 0 auto;
      font-size: 4.4rem;
      font-weight: 500;
      line-height: calc(64 / 44);
      color: color.$site-main;

      @include mixin.sp {
        width: calc(100% - 40px);
        font-size: 3.6rem;
      }
    }

    &_tab {
      display: flex;
      // justify-content: center;
      column-gap: 20px;
      width: 100%;
      max-width: 944px;
      margin: 48px auto 0;
      padding: 0 100px;
      overflow: auto;
      position: relative;

      @include mixin.sp {
        column-gap: 10px;
        width: calc(100% - 40px);
        padding: 0;
        margin-top: 25px;
      }

      &::after {
        content: "";
        display: block;
        width: 100%;
        height: 2px;
        background-color: color.$site-main;
        position: absolute;
        bottom: 0;
        left: 0;
      }

      // Javascript
      &.js-newsTab {
        .news_tab_item {
          // Modifier
          &-active {
            color: color.$site-main;
            background-color: #fff;
          }
        }
      }

      &_item {
        display: block;
        width: calc((100% / 2) - (20px / 2));
        max-width: 366px;
        text-align: center;
        color: #fff;
        border: 2px solid color.$site-main;
        background-color: color.$site-main;
        font-size: 2rem;
        line-height: calc(29 / 20);
        font-weight: 500;
        padding: 21px;
        cursor: pointer;
        transition: color 0.3s ease, background-color 0.3s ease;

        @include mixin.hover {
          color: color.$site-main;
          background-color: #fff;
        }

        @include mixin.sp {
          width: calc((100% / 2) - (10px / 2));
          font-size: 1.5rem;
          padding: 10px;
        }
      }
    }

    &_content {
      display: block;
      width: 100%;
      max-width: 944px;
      margin: 37px auto 0;
      padding: 0 92px 0 54px;

      @include mixin.sp {
        width: calc(100% - 40px);
        margin-top: 15px;
        padding: 0 15px;
      }

      // Javascript
      &.js-newsContent {
        .news_content_list {
          display: none;

          // Modifier
          &-active {
            display: block;
          }
        }
      }

      &_list {
        display: none;
        width: 100%;

        &_head {
          display: flex;
          column-gap: 20px;
          width: 100%;

          @include mixin.sp {
            flex-direction: column;
            column-gap: 0;
          }

          &_description {
            width: calc(100% - 92px - 20px);
            font-size: 1.8rem;
            line-height: calc(26 / 18);
            margin: 0;
            padding-top: 11px;

            @include mixin.sp {
              width: 100%;
              font-size: 1.4rem;
              padding-top: 0;
            }
          }

          &_link {
            display: block;
            width: 100%;
            max-width: 92px;
            border-radius: 25px;
            border: 2px solid color.$site-main;
            background-color: #fff;
            font-size: 1.8rem;
            line-height: calc(26 / 18);
            text-decoration: none;
            text-align: center;
            padding: 9px 12px 10px;
            transition: color 0.3s ease, background-color 0.3s ease;

            @include mixin.hover {
              color: #fff;
              background-color: color.$site-main;
            }

            @include mixin.sp {
              max-width: 200px;
              padding: 8px 0 9px;
              font-size: 1.6rem;
              margin: 30px auto 0;
            }
          }
        }

        &_item {
          display: block;
          width: 100%;
          margin: 36px 0 0;

          @include mixin.sp {
            margin-top: 25px;
          }

          &_inner {
            display: flex;
            align-items: flex-start;
            column-gap: 28px;
            color: inherit;
            text-decoration: none;
            @include mixin.hoverOpacity60;

            @include mixin.sp {
              column-gap: 15px;
            }
          }

          &_image {
            display: block;
            width: 100%;
            max-width: 133px;
            height: auto;
            aspect-ratio: 1/0.684;

            @include mixin.sp {
              width: 110px;
            }

            & > img {
              display: block;
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }

          &_text {
            display: block;
            width: calc(100% - 133px - 28px);

            @include mixin.sp {
              width: calc(100% - 110px - 15px);
            }

            &_information {
              display: flex;
              flex-wrap: wrap;
              column-gap: 14px;
              row-gap: 12px;
              align-items: center;
              width: 100%;
              margin-bottom: 3px;

              @include mixin.sp {
                column-gap: 8px;
                row-gap: 6px;
                margin-bottom: 6px;
              }

              &_category {
                display: block;
                min-width: 82px;
                font-size: 1.5rem;
                line-height: calc(21 / 16);
                margin: 0;
                padding: 1px 11px 1px;
                color: #fff;
                background-color: color.$site-main;
                text-align: center;

                @include mixin.sp {
                  min-width: 0;
                  font-size: 1.3rem;
                  padding: 1px 8px 2px;
                }

                // Modifier
                &-iryo {
                  background-color: color.$site-support-iryo;
                }
                &-kaigo {
                  background-color: color.$site-support-kaigo;
                }
                &-kenko {
                  background-color: color.$site-support-kenko;
                }
                &-yobo {
                  background-color: color.$site-support-yobo;
                }
                &-kyoiku {
                  background-color: color.$site-support-kyoiku;
                }
                &-chiiki {
                  background-color: color.$site-support-chiiki;
                }
              }

              &_date {
                display: block;
                font-size: 1.6rem;
                line-height: calc(24 / 16);
                margin: 0;

                @include mixin.sp {
                  font-size: 1.3rem;
                }
              }
            }

            &_title {
              font-size: 1.6rem;
              line-height: calc(24 / 16);
              margin: 0;
              font-weight: 500;

              @include mixin.sp {
                font-size: 1.3rem;
              }
            }
          }
        }
      }
    }

    &_banner {
      display: block;
      width: 100%;
      max-width: 846px;
      margin: 86px auto 0;

      @include mixin.sp {
        width: calc(100% - 60px);
        margin-top: 50px;
      }

      &_item {
        display: block;
        width: 100%;
        @include mixin.hoverOpacity60;
        margin-top: 39px;

        @include mixin.sp {
          margin-top: 20px;
        }

        & > img {
          display: block;
          width: 100%;
        }
      }
    }
  }
}
